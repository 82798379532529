import {useState} from 'react';
import AudienceDemographicsMosaic from '@analytics/components/audience-intel/AudienceDemographicsMosaic';
import AudienceExperianTypes from '@analytics/components/audience-intel/AudienceExperianTypes';
import AudienceNielsenOccupations from '@analytics/components/audience-intel/AudienceNielsenOccupations';
import {AdvisorPodcastRouteProps} from './_routes';

export function AdvisorPodcastAudience({
  organization,
  podcast,
}: AdvisorPodcastRouteProps) {
  const [category, toggleCategory] = useState<'percentage' | 'index'>(
    'percentage'
  );
  const [type, toggleType] = useState<'nielsen' | 'experian'>('experian');

  const audiencePodcast = {
    id: podcast?.feed.podcast?.id,
    feed: podcast?.feed,
  };

  return (
    <>
      <AudienceDemographicsMosaic
        organization={organization}
        podcast={audiencePodcast}
        nielsenApproval
        experianApproval
        category={category}
        toggleCategory={toggleCategory}
        toggleType={toggleType}
        type={type}
      />
      {type === 'experian' && (
        <AudienceExperianTypes
          category={category}
          experianApproval
          organization={organization}
          podcast={audiencePodcast}
        />
      )}

    </>
  );
}
