import styled from 'styled-components';
import {AdvisorRouteProps} from './_routes';

export function Advisor() {
  return (
    <Notice>
      This feature is unavailable at this time. Thank you for your
      understanding! Reach out to{' '}
      <a href='mailto:support-adanalytics@spotify.com'>
        support-adanalytics@spotify.com
      </a>{' '}
      for further assistance.
    </Notice>
  );
}

const Notice = styled.div`
  display: flex;
  font-size: 14px;
  font-size: 16px;
  padding: 4rem 2.5rem 2.5rem;
  text-align: center;
`;
