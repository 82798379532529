import {useState} from 'react';
import {capitalize} from '@mui/material';
import {gtag} from '@core/lib/gtag';
import {ButtonIcon} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import SegmentedControls from '@core/ui/SegmentedControls';
import {Text} from '@core/ui/Text';
import {ExportButton} from '@analytics/components/ExportOverlay';
import {FetchedPodcast} from '@analytics/graphql-api';
import {AudienceCategory, AudienceType} from './types';

export const ArrowButton = ({
  expanded,
  onClick,
}: {
  expanded: boolean;
  onClick?: () => void;
}) => (
  <ButtonIcon
    icon='arrow-right'
    onClick={onClick}
    css={{transform: expanded ? 'rotate(90deg)' : ''}}
  />
);

type Props = {
  category: AudienceCategory;
  disabled?: boolean;
  organizationId: string;
  podcast: FetchedPodcast;
  toggleCategory: (c: AudienceCategory) => void;
  toggleType?: (t: AudienceType) => void;
  type?: AudienceType;
};

export default function AudienceHeader({
  category,
  disabled,
  organizationId,
  podcast,
  toggleCategory,
  toggleType,
  type,
}: Props) {
  const [expanded, toggleExpanded] = useState(true);

  return (
    <>
      <Stack
        alignItems='center'
        justifyContent='space-between'
        css={{margin: '2rem 1.5rem 1rem'}}>
        <Stack spacing='var(--spacing-2)'>
          <SegmentedControls
            items={[
              {title: 'Percentage', value: 'percentage'},
              {title: 'Index', value: 'index'},
            ]}
            onChange={({value}) => {
              toggleCategory(value as AudienceCategory);
              gtag('event', value, {
                event_category: 'audience_intel',
              });
            }}
            css={{marginRight: '1rem'}}
          />
          {type && (
            <SegmentedControls
              items={[
                {title: 'Experian', value: 'experian'},
              ]}
              onChange={({value}) => {
                toggleType?.(value as AudienceType);
                gtag('event', value, {
                  event_category: 'audience_intel',
                });
              }}
            />
          )}
        </Stack>
        <ExportButton
          disabled={disabled}
          kind='segments'
          objectType='feed'
          organizationId={organizationId}
          pk={podcast.feed?.id ?? ''}
          onClick={() => {
            gtag('event', 'export_audience_data', {
              event_category: 'audience_intel_export',
            });
          }}>
          Export Audience Data
        </ExportButton>
      </Stack>
      <Card variant='filled' css={{border: 'none'}}>
        <Stack justifyContent='space-between'>
          <Text color='muted' variant='h4'>
            Understanding {capitalize(category)} Charts
          </Text>
          <ArrowButton
            expanded={expanded}
            onClick={() => toggleExpanded(!expanded)}
          />
        </Stack>
        {expanded && (
          <>
            <Text color='muted' variant='body2' css={{margin: '0.5rem 0'}}>
              {category === 'index'
                ? `The indices below express the difference between your podcast's
                audience and the US general population. An index of 100 indicates that
                the audience segment is in line with the US population. An index
                greater than 100 indicates that the audience segment is
                over-represented in your podcast compared to the US general
                population, and an index less than 100 indicates that your podcast's
                audience is under-represented compared to the US general population.`
                : `The percentages below indicate the percentage of your podcast's audience
                that makes up each segment. Percentages may add up to > 100% if the categories
                are not mutually exclusive. They may also be <100% if the data source (i.e. Experian
                  or Nielsen) did not survey the entire population for this segment.`}
            </Text>
            <Text color='muted' variant='body2'>
              <Icon
                icon='info-small'
                rules={() => ({height: '10px', marginRight: '0.275rem'})}
              />
              <i>
                This feature is currently in beta. Please reach out to our
                support team at{' '}
                <Link
                  color='muted'
                  href='mailto:support-adanalytics@spotify.com'>
                  support-adanalytics@spotify.com
                </Link>{' '}
                with any questions.
              </i>
            </Text>
          </>
        )}
      </Card>
    </>
  );
}
